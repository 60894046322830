import axios from 'axios'
import {get, post, put} from '../utils/request'
axios.prototype.get = get
axios.prototype.post = post
axios.prototype.put = put


export default {
    // 学生注册-发送短信验证码
    usersmsregist(data) {
        return axios.post('user/sms/regist',data)
    },
    // 学生注册
    userregist(data) {
        return axios.post('user/regist',data)
    },
    // 忘记密码-发送短信验证码
    usersmsresetpwd(data) {
        return axios.post('user/sms/resetpwd',data)
    },
    // 忘记密码
    userresetpwd(data) {
        return axios.post('user/resetpwd',data)
    },
    // 登录
    userlogin(data) {
        return axios.post('user/login',data)
    },
    // 修改登录密码
    changepw(data) {
        return axios.post('user/changepw',data)
    },
    // 更新用户基本信息
    userupdate(data) {
        return axios.post('user/update',data)
    },
    //退出登录
    userlogout(data) {
        return axios.post('user/logout',data)
    },


    /**
        教师端
    **/
        
    //导出小组报告
    projectsexport(data) {
        return axios.post('manage/project/export',data)
    },
    // 课程统计
    projectstatis(data) {
        return axios.post('manage/project/statis',data)
    },
    // 获取案例列表
    TeacaseList(data) {
        return axios.post('manage/course/case/list',data)
    },
    // 获取课堂分页数据
    Teacasepage(data) {
        return axios.post('manage/course/page',data)
    },
    // 新建课堂
    Teacreate(data) {
        return axios.post('manage/course/create',data)
    },
    // 删除课堂
    TeacreateDel(data) {
        return axios.post('manage/course/del',data)
    },
    // 获取课堂也详情
    Teacreatedetail(data) {
        return axios.post('manage/course/detail',data)
    },
    // 获取小组作业纸列表
    Teacreateteam(data) {
        return axios.post('manage/course/team',data)
    },
    // 设置开课
    Teacreatestart(data) {
        return axios.post('manage/course/start',data)
    },
    // 设置课堂步骤进行下一轮
    Teacreatesstep(data) {
        return axios.post('manage/course/sstep',data)
    },
    // 设置结课
    Teacreateend(data) {
        return axios.post('manage/course/end',data)
    },
    // 获取课堂资料库_列表
    TeaDocList(data) {
        return axios.post('manage/course/doc/list',data)
    },
    //课堂资料库_添加
    TeaDocAdd(data) {
        return axios.post('manage/course/doc/add',data)
    },
    //课堂资料库_删除
    TeaDocDel(data) {
        return axios.post('manage/course/doc/del',data)
    },
    //获取倒计时
    coursectdGet(data) {
        return axios.post('manage/course/ctdGet',data)
    },
    //新建倒计时
    coursecctdAdd(data) {
        return axios.post('manage/course/ctdAdd',data)
    },
    //开始倒计时
    ctdStart(data) {
        return axios.post('manage/course/ctdStart',data)
    },
    //s1获取数据
    projects1(data) {
        return axios.post('manage/project/s1',data)
    },
    //s1获取数据
    projects2(data) {
        return axios.post('manage/project/s2',data)
    },
    //s1获取数据
    projects3(data) {
        return axios.post('manage/project/s3',data)
    },
    //s1获取数据
    projects4(data) {
        return axios.post('manage/project/s4',data)
    },
    //s1获取数据
    projects5(data) {
        return axios.post('manage/project/s5',data)
    },
    //s1获取数据
    projects6(data) {
        return axios.post('manage/project/s6',data)
    },
    //s1获取数据
    projects7(data) {
        return axios.post('manage/project/s7',data)
    },
    //s1获取数据
    projects8(data) {
        return axios.post('manage/project/s8',data)
    },
    //s1获取数据
    projects9(data) {
        return axios.post('manage/project/s9',data)
    },
    //撤回可行优化
    coursereset7(data) {
        return axios.post('manage/course/reset7',data)
    },

    


    /**
        学生端
    **/
    //转让组长
    courselead(data) {
        return axios.post('course/lead',data)
    },
    //删除组员
    courseremove(data) {
        return axios.post('course/remove',data)
    },

    //获取我加入的课堂_列表
    StuCourseList(data) {
        return axios.post('course/list',data)
    },
    //通过课堂码查询课堂
    StuCoursecode(data) {
        return axios.post('course/code',data)
    },
    // 加入课堂
    StuCoursejoin(data) {
        return axios.post('course/join',data)
    },
    // 更换小组
    StuCourseteam(data) {
        return axios.post('course/team',data)
    },
    // 更换小组
    StuCoursswitchTeam(data) {
        return axios.post('course/switchTeam',data)
    },
    // 获取课堂资料库_列表
    StuCoursedoc(data) {
        return axios.post('course/doc',data)
    },
    // 获取课堂基本信息
    StuCourseinfo(data) {
        return axios.post('course/info',data)
    },
    // 获取课堂步骤数据
    StuCoursestep(data) {
        return axios.post('course/step',data)
    },
    // 获取课堂步骤数据
    StuCoursenext(data) {
        return axios.post('course/next',data)
    },
    // 获取s1(认知梳理)页面数据
    StuCprojectgets1(data) {
        return axios.post('project/get/s1',data)
    },
    // 获取s1(认知梳理)页面数据
    StuCprojectsets1(data) {
        return axios.post('project/set/s1',data)
    },
    // 获取s1(认知梳理)页面数据
    s1filter(data) {
        return axios.post('project/set/s1filter',data)
    },
    // 获取s2(资料汇集)页面数据
    StuCprojectgets2(data) {
        return axios.post('project/get/s2',data)
    },
    // 提交s2(资料汇集)节点数据
    StuCprojectsets2(data) {
        return axios.post('project/set/s2',data)
    },
    // 删除s2(资料汇集)节点数据
    StuCprojectdels2(data) {
        return axios.post('project/del/s2',data)
    },
    //  获取s3(重构焦点)数据格式 
    StuCprojectgets3(data) {
        return axios.post('project/get/s3',data)
    },
    // 提交s3(重构焦点)数据格式 
    StuCprojectsets3(data) {
        return axios.post('project/set/s3',data)
    },
    // 删除s3(重构焦点)数据格式 
    StuCprojectdels3(data) {
        return axios.post('project/del/s3',data)
    },
    // 获取s4(底层构建)数据格式
    StuCprojectgets4(data) {
        return axios.post('project/get/s4',data)
    },
    // 提交s4(底层构建)数据格式
    StuCprojectsets4(data) {
        return axios.post('project/set/s4',data)
    },
    // 删除s4(底层构建)数据格式
    StuCprojectdels4(data) {
        return axios.post('project/del/s4',data)
    },
    // 获取s5(草图描述)数据格式
    StuCprojectgets5(data) {
        return axios.post('project/get/s5',data)
    },
    // 提交s5(草图描述)数据格式
    StuCprojectsets5(data) {
        return axios.post('project/set/s5',data)
    },
    // 删除s5(草图描述)数据格式
    StuCprojectdels5(data) {
        return axios.post('project/del/s5',data)
    },
    // 获取s6(模型制作)数据格式
    StuCprojectgets6(data) {
        return axios.post('project/get/s6',data)
    },
    // 提交s6(模型制作)数据格式
    StuCprojectsets6(data) {
        return axios.post('project/set/s6',data)
    },
    // 删除s6(模型制作)数据格式
    StuCprojectdels6(data) {
        return axios.post('project/del/s6',data)
    },
    // 获取s7(推演测试)数据格式
    StuCprojectgets7(data) {
        return axios.post('project/get/s7',data)
    },
    // 提交s7(推演测试)数据格式
    StuCprojectsets7(data) {
        return axios.post('project/set/s7',data)
    },
    // 删除s7(推演测试)数据格式
    StuCprojectdels7(data) {
        return axios.post('project/del/s7',data)
    },
    // 获取s8(可行优化)数据格式
    StuCprojectgets8(data) {
        return axios.post('project/get/s8',data)
    },
    // 提交s8(可行优化)数据格式
    StuCprojectsets8(data) {
        return axios.post('project/set/s8',data)
    },
    // 删除s8(可行优化)数据格式
    StuCprojectdels8(data) {
        return axios.post('project/del/s8',data)
    },
    // 获取s9(最简呈现)数据格式
    StuCprojectgets9(data) {
        return axios.post('project/get/s9',data)
    },
    // 图片base64上传
    fileimage(data) {
        return axios.post('/file/image',data)
    },
    // 获取倒计时
    StudctdGet(data) {
        return axios.post('/course/ctdGet',data)
    },
    // S1提交
    submits1(data) {
        return axios.post('/course/submit/s1',data)
    },
    // S2提交
    submits2(data) {
        return axios.post('/course/submit/s2',data)
    },
    // S3提交
    submits3(data) {
        return axios.post('/course/submit/s3',data)
    },
    // S4提交
    submits4(data) {
        return axios.post('/course/submit/s4',data)
    },
    // S5提交
    submits5(data) {
        return axios.post('/course/submit/s5',data)
    },
    // S6提交
    submits6(data) {
        return axios.post('/course/submit/s6',data)
    },
    // S7提交
    submits7(data) {
        return axios.post('/course/submit/s7',data)
    },
    // S8提交
    submits8(data) {
        return axios.post('/course/submit/s8',data)
    },
    // 提交s7(推演测试)数据格式
    StuCprojectsets9(data) {
        return axios.post('project/set/s9',data)
    }

  
}