import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};

const VueRouterPush =VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const routes = [
  {
    path:'/',
    redirect:'/login'
  },
  // // 登录login
  {
    path:'/login',
    name:'login',
    component:()=>import("@/views/login/login.vue"),
  },
  {
    path:'/student',
    name:'student',
    component:()=>import("@/views/student/student.vue"),
    redirect: {
      name:'Myclass'
    },
    children:[
      // 加入课堂
      {
        path:'/Myclass',
        name:'Myclass',
        component:()=>import("@/views/student/Myclass.vue"),
      },
      // 账号设置
      {
        path:'/StudentSetting',
        name:'StudentSetting',
        component:()=>import("@/views/student/StudentSetting.vue"),
      },
    ]
  },
  // 学生端
  {
    path:'/studentIndex',
    name:'studentIndex',
    component:()=>import("@/views/student/index.vue"),
    redirect: {
      name:'Cognitivecomb'
    },
    children:[
      // 认知梳理
      {
        path:'/Cognitivecomb',
        name:'Cognitivecomb',
        component:()=>import("@/views/student/Cognitivecomb.vue"),
      },
      // 资料汇集
      {
        path:'/Datacollection',
        name:'Datacollection',
        component:()=>import("@/views/student/Datacollection.vue"),
      },
      // 重构焦点
      {
        path:'/Refactorinfocus',
        name:'Refactorinfocus',
        component:()=>import("@/views/student/Refactorinfocus.vue"),
      },
      // 底层构建
      {
        path:'/Derlyconstruct',
        name:'Derlyconstruct',
        component:()=>import("@/views/student/Derlyconstruct.vue"),
      },
      // 草图描述
      {
        path:'/Sketchtodescribe',
        name:'Sketchtodescribe',
        component:()=>import("@/views/student/Sketchtodescribe.vue"),
      },
      // 模型制作
      {
        path:'/Modelmaking',
        name:'Modelmaking',
        component:()=>import("@/views/student/Modelmaking.vue"),
      },
      // 推演测试
      {
        path:'/Exercisetesting',
        name:'Exercisetesting',
        component:()=>import("@/views/student/Exercisetesting.vue"),
      },
      // 可行优化
      {
        path:'/Feamization',
        name:'Feamization',
        component:()=>import("@/views/student/Feamization.vue"),
      },
      // 最简呈现
      {
        path:'/Minimaering',
        name:'Minimaering',
        component:()=>import("@/views/student/Minimaering.vue"),
      },
    ]
  },
  //教师端
  {
    path:'/teacher',
    name:'teacher',
    component:()=>import("@/views/teacher/index.vue"),
    redirect: {
      name:'coursePage'
    },
    children:[
      // 课程首页
      {
        path:'/coursePage',
        name:'coursePage',
        component:()=>import("@/views/teacher/coursePage.vue"),
        redirect:{
          name:'Courseinformation'
        },
        children:[
          // 课程信息——添加课程
          {
            path:'/Courseinformation',
            name:'Courseinformation',
            component:()=>import("@/views/teacher/Courseinformation.vue"),
          },
          //个人中心
          {
            path:'/Personalcenter',
            name:'Personalcenter',
            component:()=>import("@/views/teacher/Personalcenter.vue"),
          }
          
        ]
      },
      //课程详情
      {
        path:'/Coursedetails',
        name:'Coursedetails',
        component:()=>import("@/views/teacher/Coursedetails.vue"),
      },
      //课程统计
      {
        path:'/Coursestatistics',
        name:'Coursestatistics',
        component:()=>import("@/views/teacher/Coursestatistics.vue"),
      },
      // 教师端作业纸
      {
        path:'/CopyIndex',
        name:'CopyIndex',
        component:()=>import("@/views/teacher/Copypaper/CopyIndex.vue"),
        redirect:{
          name:'CognitiveRZSL'
        },
        children:[
          // 认知梳理
          {
            path:'/CognitiveRZSL',
            name:'CognitiveRZSL',
            component:()=>import("@/views/teacher/Copypaper/CognitiveRZSL.vue"),
          },
          // 资料收集
          {
            path:'/CognitiveZLSJ',
            name:'CognitiveZLSJ',
            component:()=>import("@/views/teacher/Copypaper/CognitiveZLSJ.vue"),
          },
          // 重构焦点
          {
            path:'/CognitiveCGJD',
            name:'CognitiveCGJD',
            component:()=>import("@/views/teacher/Copypaper/CognitiveCGJD.vue"),
          },
          // 底层构建
          {
            path:'/CognitiveDCGJ',
            name:'CognitiveDCGJ',
            component:()=>import("@/views/teacher/Copypaper/CognitiveDCGJ.vue"),
          },
          // 草图描述
          {
            path:'/CognitiveCTMS',
            name:'CognitiveCTMS',
            component:()=>import("@/views/teacher/Copypaper/CognitiveCTMS.vue"),
          },
          // 模型制作
          {
            path:'/CognitiveMXZZ',
            name:'CognitiveMXZZ',
            component:()=>import("@/views/teacher/Copypaper/CognitiveMXZZ.vue"),
          },
          // 推演测试
          {
            path:'/CognitiveTYCS',
            name:'CognitiveTYCS',
            component:()=>import("@/views/teacher/Copypaper/CognitiveTYCS.vue"),
          },
          // 可行优化
          {
            path:'/CognitiveKXYH',
            name:'CognitiveKXYH',
            component:()=>import("@/views/teacher/Copypaper/CognitiveKXYH.vue"),
          },
          // 最简呈现
          {
            path:'/CognitiveZJCX',
            name:'CognitiveZJCX',
            component:()=>import("@/views/teacher/Copypaper/CognitiveZJCX.vue"),
          },
          
        ]
      },
    ],
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
